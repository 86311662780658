import Vue from 'vue'
import Vuex from 'vuex'

import app from '@/store/modules/app'
import emissions from '@/store/modules/emissions'
import media from '@/store/modules/media'
import recordings from '@/store/modules/recordings'
import users from '@/store/modules/users'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    app,
    emissions,
    recordings,
    media,
    users,
  },
  strict: debug,
})