import actions from './actions';
import mutations from './mutations';

// initial state
const state = () => ({
  checkoutStatus: null,
  currentPage: 1,
  items: [],
  perPage: 25,
  recording: {
    end_date: '',
    file_url: '',
    country_start_time: '',
    country_end_time: '',
    start_date: '',
    status: '',
    statusTransfer: '',
    title: '',
    type: '',
  },
  selectedSmartMediaSource: '',
  smartMediaSources: [],
  sortKey: '',
  sortOrder: '',
})

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
