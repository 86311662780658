export default {
  name: 'VedemLocaleChanger',
  data () {
    return { langs: ['en', 'fr'] }
  },
  methods: {
    handleLocaleClick(locale) {
      this.$i18n.locale = locale;
      window.localStorage.setItem('locale', locale);
    },
  }
}
