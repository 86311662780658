import Vue from 'vue'
import VueAxios from 'vue-axios'
import { BootstrapVue } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import 'es6-promise/auto'

import router from './router'
import store from './store';
import axios from './utils/axios';
import i18n from './i18n'

import './validations';

// Base Components
import VedemAlert from '@/components/Base/Alert/index.vue';
import VedemApp from '@/components/App/index.vue'
import VedemCheckbox from '@/components/Base/Checkbox/index.vue';
import VedemInput from '@/components/Base/Input/index.vue';
import VedemMultiCheckbox from '@/components/Base/MultiCheckbox/index.vue';
import VedemProgress from '@/components/Base/Progress/index.vue';
import VedemSelect from '@/components/Base/Select/index.vue';
import VedemTable from '@/components/Base/Table/index.vue';

// Complex Components
import VedemBreadcrumb from '@/components/Breadcrumb/index.vue';
import VedemDetailsButton from '@/components/DetailsButton/index.vue';
import VedemFilterContainer from '@/components/FilterContainer/index.vue';
import VedemSidebar from '@/components/Sidebar/index.vue';
import VedemStatus from '@/components/Status/index.vue';

// Layouts
import VedemBaseLayout from '@/layouts/BaseLayout/index.vue';
import VedemFooter from '@/components/Footer/index.vue';
import VedemHeader from '@/components/Header/index.vue';
import VedemLocaleChanger from '@/components/LocaleChanger/index.vue';
import VedemMainLayout from '@/layouts/MainLayout/index.vue';
import VedemNavItem from '@/components/NavItem/index.vue';
import VedemRecordingCircle from '@/components/RecordingCircle/index.vue';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VedemAlert', VedemAlert);
Vue.component('VedemApp', VedemApp);
Vue.component('VedemBaseLayout', VedemBaseLayout);
Vue.component('VedemBreadcrumb', VedemBreadcrumb);
Vue.component('VedemCheckbox', VedemCheckbox);
Vue.component('VedemDetailsButton', VedemDetailsButton);
Vue.component('VedemFilterContainer', VedemFilterContainer);
Vue.component('VedemFooter', VedemFooter);
Vue.component('VedemHeader', VedemHeader);
Vue.component('VedemInput', VedemInput);
Vue.component('VedemLocaleChanger', VedemLocaleChanger);
Vue.component('VedemMainLayout', VedemMainLayout);
Vue.component('VedemMultiCheckbox', VedemMultiCheckbox);
Vue.component('VedemNavItem', VedemNavItem);
Vue.component('VedemProgress', VedemProgress);
Vue.component('VedemRecordingCircle', VedemRecordingCircle);
Vue.component('VedemSelect', VedemSelect);
Vue.component('VedemSidebar', VedemSidebar);
Vue.component('VedemStatus', VedemStatus);
Vue.component('VedemTable', VedemTable);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/scss/bootstrap-custom.scss';

import './fa.config';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(VedemApp)
}).$mount('#app')
