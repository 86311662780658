import Vue from 'vue'
import VueRouter from 'vue-router'

import mediaRoutes from './media';
import emissionRoutes from './emissions';
import recordingRoutes from './recordings';
import passwordRecoveryRoutes from './password_recovery';
import userRoutes from './users';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/index.vue'),
  },
  {
    path: '/sign_in',
    name: 'Signin',
    component: () => import('@/views/SignIn/index.vue'),
  },
  ...mediaRoutes,
  ...emissionRoutes,
  ...recordingRoutes,
  ...userRoutes,
  ...passwordRecoveryRoutes,
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound/index.vue'),
  }
]

const router = new VueRouter({
  // eslint-disable-next-line
  base: '/',
  routes
})

export default router
