export default {
  name: 'VedemDetails',
  props: [
    'editLabel', 
    'deleteLabel', 
    'id', 
    'label',
    'handleViewClick', 
    'handleEditClick', 
    'handleDeleteClick', 
  ],
  computed: {
    showModify() {
      return !!(this.handleDeleteClick) || !!(this.handleEditClick);
    },

    viewButtonClass () {
      return `button bg-gray view_details_button${!this.showModify ? " view_details_button_only" : ""}`;
    },
  }
}
