import { mapState } from 'vuex';

export default {
  name: "VedemSidebar",
  methods: {
    navChildren(parent_id) {
      return this.navItems.filter(n => n.parent === parent_id);
    },
  },
  computed: {
    parentNavItems() {
      return this.navItems.filter(n => !n.parent);
    },

    ...mapState({
      navItems: state => state.app.navItems,
      selected: state => state.app.selectedNav,
    }),
  },
};
