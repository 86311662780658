import { mapState, mapMutations } from 'vuex';

export default {
  name: 'VedemNavItem',
  props: ['navItem'],
  computed: {
    ...mapState({
      navItems: state => state.app.navItems,
      currentUser: state => state.app.currentUser,
      selectedNav: state => state.app.selectedNav,
    }),
  },
  methods: {
    isParent(id) {
      return this.navItems.some(n => n.parent === id);
    },

    isSelected(id) {
      const selectedNavItem = this.navItems.find(i => i.id === this.selectedNav);
      
      return this.selectedNav === id || selectedNavItem.parent === id;
    },

    isSelectedParent(id) {
      const selectedNavItem = this.navItems.find(i => i.id === this.selectedNav);

      return selectedNavItem.parent === id;
    },
    
    navChildren(parent_id) {
      return this.navItems.filter(n => n.parent === parent_id);
    },

    onClickRedirect(navItem, isParent) {
      if(!isParent) {
        this.setSelectedNav(navItem.id);
        if (this.$route.path == navItem.path) { return }
        this.$router.push(navItem.path);
      }
    },

    ...mapMutations('app', [
      'setSelectedNav',
    ]),
  },
};
