import { mapState, mapMutations } from "vuex"

export default {
  name: 'VedemMainLayout',
  computed: {
    ...mapState({
      error: state => state.app.error,
    }),
  },
  methods: {
    ...mapMutations('app', [
      'setError'
    ])
  },
  mounted() {
    this.setError("");
  }
}
