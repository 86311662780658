export default {
  name: "VedemFooter",
  props: ["base"],
  methods: {
    goToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
}
