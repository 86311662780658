import { mapState, mapMutations } from 'vuex';

export default {
  name: 'VedemApp',
  computed: {
    ...mapState('app', [
      'alertContent',
      'alertVariant',
      'alertShown',
    ])
  },
  methods: {
    ...mapMutations('app', [
      'hideAlert'
    ])
  }
}
