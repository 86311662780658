const routes = [
  {
    path: '/password_recovery',
    component: () => import('@/views/PasswordRecovery/index.vue'),
    children: [
      {
        path: 'step2',
        name: 'password_recovery.step2',
        component: () => import('@/views/PasswordRecovery/Step2/index.vue'),
      },
      {
        path: 'step3',
        name: 'password_recovery.step3',
        component: () => import('@/views/PasswordRecovery/Step3/index.vue'),
      },
      {
        path: '',
        name: 'password_recovery.step1',
        component: () => import('@/views/PasswordRecovery/Step1/index.vue'),
      },
    ],
  },
];

export default routes;
