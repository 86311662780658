import moment from 'moment';

import actions from './actions';
import mutations from './mutations';

// initial state
const state = () => ({
  currentPage: 1,
  items: [],
  perPage: 25,
  publication: {
    media_source_id: '',
    title: '',
    url: '',
  },
  program: {
    country_end_time: moment().add(1, 'hour').format('HH:mm'),
    country_start_time: moment().format('HH:mm'),
    end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
    frequency: 'daily',
    is_active: false,
    media_source: {},
    media_source_id: '',
    start_date: moment().format('YYYY-MM-DD'),
    title: '',
    url: '',
    week_days: [],
  },
  sortKey: '',
  sortOrder: '',
})

// getters
const getters = {
  emissions: (state) => {
    return state.items;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
