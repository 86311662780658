import actions from './actions';
import mutations from './mutations';

// initial state
const state = () => ({
  currentPage: 1,
  items: [],
  media: {
    // eslint-disable-next-line
    country: config?.VUE_APP_COUNTRY || 'Nepal',
    status: true,
    // eslint-disable-next-line
    timezone: config?.VUE_APP_TIMEZONE || 'Asia/Kathmandu',
    title: '',
    type: 'AudioMediaSource',
    url: '',
  },
  perPage: 25,
  sortKey: '',
  sortOrder: '',
  typeOptions: [
    {
      id: 1,
      label: "Radio",
      value: "AudioMediaSource"
    },
    {
      id: 2,
      label: "TV",
      value: "VideoMediaSource"
    },
    {
      id: 3,
      label: "Press",
      value: "PressMediaSource"
    },
  ],
});

// getters
const getters = {
  findMediaByID: (state, id) => {
    return state.items.find(i => i.id === id);
  },

  getTVAndRadioMedia: (state) => {
    const allowed = ['AudioMediaSource', 'VideoMediaSource'];
    return state.items.filter(m => allowed.includes(m.type));
  },

  getPressMedia: (state) => {
    return state.items.filter(m => m.type === 'PressMediaSource');
  },

  media: (state) => {
    return state.items;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
