export default {
  name: 'VedemInput',
  props: [
    'id', 
    'label', 
    'name', 
    'onChange', 
    'placeholder', 
    'type', 
    'value', 
    'validationRules',
  ],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    handleSubmit() {
      this.$emit('key:enter')
    },
  },
};
