const routes = [
  {
    path: '/media',
    component: () => import('@/views/Media/index.vue'),
    children: [
      {
        path: 'read/:id',
        name: 'media.read',
        component: () => import('@/views/Media/Create/index.vue'),
        props: {
          readOnly: true,
        }
      },
      {
        path: 'create',
        name: 'media.create',
        component: () => import('@/views/Media/Create/index.vue'),
      },
      {
        path: 'delete/:id',
        name: 'media.delete',
        component: () => import('@/views/Media/Delete/index.vue'),
      },
      {
        path: 'update/:id',
        name: 'media.update',
        component: () => import('@/views/Media/Create/index.vue'),
      },
      {
        path: '',
        name: 'media.map',
        component: () => import('@/views/Media/List/index.vue'),
      },
    ],
  },
];

export default routes;
