import axios from '../utils/axios';

export const recoverPassword = (payload) => {
  return axios({
    method: "POST",
    url: "/v1/auth/password",
    data: {
      ...payload,
      redirect_url: `${window.location.origin}/password_recovery/step3`,
    }
  });
};

export const signIn = (payload) => {
  return axios({
    method: "POST",
    url: "/v1/auth/sign_in",
    data: payload
  });
};

export const updateProfile = (payload) => {
  return axios({
    method: "PATCH",
    url: "/v1/auth",
    data: payload,
  });
}

export const updatePassword = (payload, headers) => {
  return axios({
    method: "PATCH",
    url: "/v1/auth/password",
    data: payload,
    headers,
  });
};
