export default {
  name: "VedemCheckbox",
  props: [
    'checked', 
    'handleChange', 
    'id', 
    'label', 
    'labelLeft', 
    'labelRight', 
    'name', 
    'readonly',
    'value', 
  ],
  computed: {
    inputVal: {
      get() {
        return this.checked;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};


