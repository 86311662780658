import recordingApi from '@/api/recordings'

const { 
  createRecording,
  getRecordings,
  getRecordingByID,
  getSmartMediaSources,
  getRecordingStatus,
  deleteRecording,
  updateRecording
} = recordingApi;

const actions = {
  fetchRecordings({ commit }) {
    return getRecordings().then(recordings => {
      commit('setRecordings', recordings.data);

      return recordings;
    });
  },

  fetchRecordingByID({ commit }, id) {
    return getRecordingByID(id).then(recording => {
      commit('setRecording', recording.data);

      return recording;
    });
  },

  fetchRecordingStatus({ commit }) {
    return getRecordingStatus().then(statusUpdates => { 
      commit('updateRecordingStatus', statusUpdates.data); 
      return statusUpdates;
    }).catch(error => {
      console.error('Error while retrieving recording statuses ', error);
      throw error;
    });
  },

  fetchSmartMediaSources({ commit }, id) {
    return getSmartMediaSources(id).then(data => {
      commit('setSmartMediaSources', data.media_sources);

      if(data?.media_sources.length > 0)
        commit('setSelectedSmartMediaSource', data.media_sources[0].id);

      return data;
    });
  },

  assignSelectedSmartMediaSource({ commit }, selectedSmartMediaSource) {
    commit('setSelectedSmartMediaSource', selectedSmartMediaSource);
  },

  addRecording({ commit }, payload) {
    return createRecording(payload).then(res => {
      commit('addRecording', res.data);

      return res;
    });
  },

  editRecording({ commit }, { id, payload }) {
    return updateRecording({ id, payload }).then(res => {
      commit('updateRecording', { ...res.data });

      return res;
    });
  },

  removeRecording({ commit }, id) {
    return deleteRecording(id).then((res) => {
      commit('deleteRecording', id);

      return res;
    });
  },

  setSortOrder({ commit }, order) {
    commit('setSortOrder', order);
  },

  setSortKey({ commit }, key) {
    commit('setSortKey', key);
  },
}

export default actions;
