const routes = [
  {
    path: '/recordings',
    component: () => import('@/views/Recordings/index.vue'),
    children: [
      {
        path: 'read/:id',
        name: 'recordings.read',
        component: () => import('@/views/Recordings/Read/index.vue'),
      },
      {
        path: 'delete/:id',
        name: 'recordings.delete',
        component: () => import('@/views/Recordings/Delete/index.vue'),
      },
      {
        path: 'send_to_smart/:id',
        name: 'recordings.sendToSmart',
        component: () => import('@/views/Recordings/SendToSmart/index.vue'),
      },
      {
        path: '',
        name: 'recordings.map',
        component: () => import('@/views/Recordings/List/index.vue'),
      }
    ],
  },
];

export default routes;
