export default {
  name: "VedemSelect",
  props: [
    'filterSelect',
    'label', 
    'onChange',
    'options', 
    'value', 
  ],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
