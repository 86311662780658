const routes = [
  {
    path: '/users',
    component: () => import('@/views/Admin/index.vue'),
    children: [
      {
        path: 'create',
        name: "user.create",
        component: () => import('@/views/Admin/Create/index.vue'),
      },
      {
        path: 'delete/:id',
        name: "user.delete",
        component: () => import('@/views/Admin/Delete/index.vue'),
      },
      {
        path: 'read/:id',
        name: "user.read",
        component: () => import('@/views/Admin/Create/index.vue'),
        props: {
          readOnly: true,
        }
      },
      {
        path: 'update/my_profile',
        name: "user.update_profile",
        component: () => import('@/views/Admin/Create/index.vue'),
        props: {
          profileMine: true,
        }
      },
      {
        path: 'update/:id',
        name: "user.update",
        component: () => import('@/views/Admin/Create/index.vue'),
      },
      {
        path: '',
        name: "user.map",
        component: () => import('@/views/Admin/List/index.vue'),
      }
    ],
  },
];

export default routes;
