import axios from './axios';
import moment from 'moment-timezone';
import momentDurationFormatSetup from "moment-duration-format";

import {
  TASK_STATUS_DONE_OK,
  TRANSFER_STATUS_PENDING,
  TRANSFER_STATUS_IN_PROGRESS,
  TRANSFER_STATUS_FINISHED,
} from './protocol';

momentDurationFormatSetup(moment);

export const crud = (slug, label) => {
  const methods = {};

  methods[`get${label}s`] = (params = {}) => axios({
    method: 'GET',
    url: slug,
    params,
  }).then((res) => {
    console.log(`get${label}s: ${res}`)
    return res.data
  });

  methods[`get${label}ByID`] = (id) => axios({
    method: 'GET',
    url: `${slug}/${id}`,
  }).then((res) => res.data);

  methods[`create${label}`] = (payload) => axios({
    method: 'POST',
    url: slug,
    data: payload,
  }).then((res) => res.data);

  methods[`update${label}`] = ({ id, payload }) => axios({
    method: 'PATCH',
    url: `${slug}/${id}`,
    data: payload,
  }).then(res => res.data);

  methods[`delete${label}`] = (id) => axios({
    method: 'DELETE',
    url: `${slug}/${id}`,
  }).then(res => res.data);

  return methods;
}

export const errorHandler = (e) => {
  console.log(e);
}

export const differenceInTime = function(a, b) {
  const x = moment(a);
  const y = moment(b);

  x.set({ year: 2000, month: 1, date: 1 });
  y.set({ year: 2000, month: 1, date: 1 });
  
  return moment.duration(y.diff(x));
}

export const getRoleNameByValue = (value) => {
  return roleOptions.find(role => role.value === value).label;
}

export const roleOptions = [
  {
    id: 1,
    label: "Lecteur",
    value: "reader"
  },
  {
    id: 2,
    label: "Consultant",
    value: "consultant"
  },
  {
    id: 3,
    label: "Administrateur",
    value: 'admin'
  }
];

export const permissionOptions = [
  {
    id: 1,
    label: "Press",
    value: "PressMediaSource"
  },
  {
    id: 2,
    label: "Video",
    value: "VideoMediaSource"
  },
  {
    id: 3,
    label: "Audio",
    value: "AudioMediaSource"
  },
];

export const minutesOfDay = function(time) {
  const m = moment(time);
  return m.minutes() + m.hours() * 60;
}

export const resultsPerPageOptions = [
  {
    id: 1,
    label: '4',
    value: 4,
  },
  {
    id: 2,
    label: '25',
    value: 25,
  },
  {
    id: 3,
    label: '50',
    value: 50,
  },
  {
    id: 4,
    label: '100',
    value: 100,
  },
];

const returnBroadcastStatusType = (now, country_start_time, country_end_time) => {
  if(minutesOfDay(now) >= minutesOfDay(country_start_time) && minutesOfDay(now) <= minutesOfDay(country_end_time)) {
    return "error";
  } else if (minutesOfDay(now) < minutesOfDay(country_start_time)) {
    const duration = differenceInTime(now, country_start_time);

    if(duration.hours() < 3 && duration.hours() >= 0)
      return `progress`;

    return "success";
  }
  
  return "success"
};

export const returnTaskStatusType = (status) => {  
  if(status) {
    if(status.toLowerCase() === "done_error" || status.toLowerCase() === "plugin_error")
      return 'error';
    else if(status.toLowerCase() === 'done_ok')
      return 'success';
    else if(status.toLowerCase().includes('in_progress'))
      return 'progress';
    else
      return 'default';
  } else {
    return 'default';
  }
};

export const returnTransferStatusType = (transferStatus, taskStatus) => {
  if (taskStatus && taskStatus.toLowerCase() === TASK_STATUS_DONE_OK) {
    if (transferStatus) {
      switch (transferStatus.toLowerCase()) {
        case TRANSFER_STATUS_PENDING:
          return 'default';
        case TRANSFER_STATUS_IN_PROGRESS:
          return 'progress';
        case TRANSFER_STATUS_FINISHED:
          return 'success';
        default:
          return 'default';
      }
    } else {
      return 'default';
    }
  } else {
    return 'error';
  }
};


export const returnEmissionStatusType = (broadcast, paramNow = moment()) => {
  const { 
    last_download_task: task,
    start_date, 
    end_date, 
    country_start_time, 
    country_end_time, 
    frequency, 
    week_days,
    media_source,
    type,
    download_task_status,
  } = broadcast;

  const now = moment(paramNow);
  const cst = moment.tz(country_start_time, 'HH:mm', media_source.timezone).format();
  const cet = moment.tz(country_end_time, 'HH:mm', media_source.timezone).format();

  if(type === "Publication") {
    if(download_task_status) {
      return returnTaskStatusType(download_task_status);
    }

    return "default";
  }

  if(frequency === 'only_once' || !(now.isBetween(moment(start_date), moment(end_date))))
    return "default";

  if(task) {
    if (now.isBetween(moment(task.utc_start_at), moment(task.utc_end_at))) {
      return "error";
    } else if (now.isAfter(moment(task.utc_start_at)) && now.isAfter(moment(task.utc_end_at))) {
      if(frequency === 'daily')
        return returnBroadcastStatusType(now, cst, cet);
      else if (frequency === 'weekly') {
        if(week_days.includes(now.format('dddd').toLowerCase())) {
          return returnBroadcastStatusType(now, cst, cet);
        }
      }

      return "success";
    } 
    
    return "success"
  } else {
    if(frequency === 'daily')
      return returnBroadcastStatusType(now, cst, cet);
    else if (frequency === 'weekly') {
      if(week_days.includes(now.format('dddd').toLowerCase())) {
        return returnBroadcastStatusType(now, cst, cet);
      }

      return "success";
    }

    return "success";
  }
};

export function titalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
