import { mapState } from 'vuex';

export default {
  name: 'VedemProgressBar',
  computed: {
    ...mapState('app', [
      'loading',
    ]),
  },
}
