import axios from 'axios';
import axiosRetry from 'axios-retry';
import store from '../store';

axiosRetry(axios, { retries: 3 });

function saveAuthHeader(headers) {
  if (headers['access-token']) {
    const authHeaders = {
      'access-token': headers['access-token'],
      client: headers.client,
      expiry: headers.expiry,
      uid: headers.uid,
    };
    localStorage.setItem('auth_headers', JSON.stringify(authHeaders));
    if (headers['refresh-token'])
      localStorage.setItem('refresh_token', headers['refresh-token']);
    return true;
  }
  return false;
}

// eslint-disable-next-line
axios.defaults.baseURL = `${config?.VUE_APP_BASE_URL || process.env.VUE_APP_BASE_URL}/api`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
  const authHeaders = JSON.parse(localStorage.getItem('auth_headers'));
  if (authHeaders != null) {
    request.headers['access-token'] = authHeaders['access-token']; 
    request.headers.client = authHeaders.client;
    request.headers.expiry = authHeaders.expiry;
    request.headers.uid = authHeaders.uid;
  }

  return request;
});

axios.interceptors.response.use(
  response => {
    saveAuthHeader(response.headers);
    return response;
  },
  async error => {
    // console.log(error.toJSON());
    let errorMessage = "An error occurred!";

    if(error?.response) {
      if (
        error.response.status === 401 &&
        window.location.pathname !== '/sign_in' &&
        !window.location.pathname.includes('/password_recovery')
      ) {
        window.location = '/#/sign_in';
        window.localStorage.clear();
      }

      const { data } = error.response;

      if(error.response.status >= 400 && error.response.status < 500) {
        if(data?.errors) {
          errorMessage = data.errors.join(', ');
        } else if (data?.error) {
          if(data?.error?.message) {
            errorMessage = data.error.message;
          } else if(typeof data.error === "string") {
            errorMessage = data.error;
          }
        } else if (data?.message) {
          if(Array.isArray(data.message)) {
            errorMessage = data.message.join();
          } else if (typeof data.message === "string") {
            errorMessage = data.message;
          } else {
            errorMessage = error.message;
          }
        } else {
          errorMessage = error.message;
        }
      } else {
        errorMessage = error.message;
        store.commit('app/setError', errorMessage);
      }
    } else {
      errorMessage = error.message;
      store.commit('app/setError', errorMessage);
    }

    store.commit('app/setAlertContent', errorMessage);
    store.commit('app/setAlertVariant', 'danger');
    store.commit('app/showAlert', 5);
    store.commit('app/setLoading', false);
  }
);

export default axios;
