import moment from 'moment';
import { extend } from 'vee-validate';
import { required, email, confirmed, min_value, max_value, min } from 'vee-validate/dist/rules';

extend('confirmed', {
  ...confirmed,
  message: 'Confirmation does not match!',
});

extend('dateBefore', {
  validate: (value, { date }) => {
    return moment(value).isSameOrBefore(date)
  },
  params: ['date'],
  message: 'Date should not be after {date}',
});

extend('dateAfter', {
  validate: (value, { date }) => {
    return moment(value).isSameOrAfter(date)
  },
  params: ['date'],
  message: 'Date should not be before {date}',
});

extend('email', {
  ...email,
  message: 'Email must be a valid one!',
});

extend('max_value', {
  ...max_value,
  message: 'Maximum value exceeded!',
});

extend('min', {
  ...min,
  message: 'Minimum length is {length}'
});

extend('min_value', {
  ...min_value,
  message: 'Minimum value exceeded!',
});

extend('required', {
  ...required,
  message: 'This field is required!',
});

extend('timeBefore', {
  validate: (value, { time }) => {
    return moment(value, 'HH:mm').isBefore(moment(time, 'HH:mm'))
  },
  params: ['time'],
  message: 'Time should not be after {time}',
}),

extend('timeAfter', {
  validate: (value, { time }) => {
    return moment(value, 'HH:mm').isAfter(moment(time, 'HH:mm'))
  },
  params: ['time'],
  message: 'Time should not be before {time}',
});