const routes = [
  {
    path: '/emissions',
    component: () => import('@/views/Emissions/index.vue'),
    children: [
      {
        path: 'create_press',
        name: "emission.createpress",
        component: () => import('@/views/Emissions/CreatePress/index.vue'),
      },
      {
        path: 'create_tvradio',
        name: "emission.tvradio",
        component: () => import('@/views/Emissions/CreateTVRadio/index.vue'),
      },
      {
        path: 'delete/:id',
        name: "emission.delete",
        component: () => import('@/views/Emissions/Delete/index.vue'),
      },
      {
        path: 'read_press/:id',
        name: "emission.readpress",
        component: () => import('@/views/Emissions/CreatePress/index.vue'),
        props: {
          readOnly: true,
        }
      },
      {
        path: 'read_tvradio/:id',
        name: "emission.readtvradio",
        component: () => import('@/views/Emissions/CreateTVRadio/index.vue'),
         props: {
          readOnly: true,
        },
      },
      {
        path: 'update_tvradio/:id',
        name: "emission.updatetvradio",
        component: () => import('@/views/Emissions/CreateTVRadio/index.vue'),
      },
      {
        path: ':emissionId/recordings',
        name: 'emission.recording',
        component: () => import('@/views/Recordings/List/index.vue')
      },
      {
        path: '',
        name: "emission.map",
        component: () => import('@/views/Emissions/List/index.vue'),
      }

    ]
  },
];

export default routes;
