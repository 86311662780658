const mutations = {
  addUser (state, user) {
    state.items = [...state.items, user]
  },

  deleteUser(state, id) {
    state.items = [...state.items.filter(u => +u.id !== +id)];
  },

  resetUser (state) {
    state.user = {
      name: '',
      password: '',
      confirm_password: '',
      email: '',
      role: 'admin',
      enabled: true,
      notify_user: true,
      permissions: [],
    };
  },

  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },

  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
  
  setUsers (state, items) {
    state.items = items;
  },

  setUser (state, user) {
    state.user = {
      ...user,
      password: '',
      confirm_password: '',
      permissions: user.permissions || [],
    };
  },

  setSortKey (state, sortKey) {
    state.sortKey = sortKey;
  },

  setSortOrder (state, sortOrder) {
    state.sortOrder = sortOrder;
  },

  toggleAllSelect(state) {
    const anyUnchecked = state.items.some(item => !item.selected);

    state.items = state.items.map(item => ({ ...item, selected: anyUnchecked}))
  },

  toggleTableRowSelect(state, id) {
    state.items = state.items.map(item => {
      if(item.id === id) {
        return {...item, selected: !item.selected};
      } else {
        return item;
      }
    })
  },

  updateUser(state, user) {
    state.items = state.items.map(u => +u.id === +user.id ? user : u);
  },

  updateUserKey(state, payload) {
    Object.keys(payload).forEach(key => {
      state.user[key] = payload[key];
    });
  }
};

export default mutations;
