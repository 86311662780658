import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faAngleDoubleLeft, 
  faAngleDoubleRight,
  faAngleDown, 
  faAngleLeft, 
  faAngleRight,
  faArrowUp,
  faBell,
  faCaretDown, 
  faCaretUp, 
  faChevronUp, 
  faChevronDown,
  faExclamationTriangle,
  faFile, 
  faGlobeAmericas, 
  faHome, 
  faMinus,
  faPlus,
  faSignOutAlt,
  faTools,
  faUserAlt,
  faWifi, 
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faAngleDoubleLeft, 
  faAngleDoubleRight,
  faAngleDown, 
  faAngleLeft, 
  faAngleRight,
  faArrowUp,
  faBell,
  faCaretDown, 
  faCaretUp, 
  faChevronUp, 
  faChevronDown,
  faExclamationTriangle,
  faFile, 
  faGlobeAmericas, 
  faHome, 
  faMinus,
  faPlus,
  faSignOutAlt,
  faTools,
  faUserAlt,
  faWifi, 
);

Vue.component('font-awesome-icon', FontAwesomeIcon); // registered globally
