import mutations from './mutations';
import actions from './actions';

// initial state
const state = () => ({
  currentPage: 1,
  items: [],
  perPage: 25,
  sortKey: '',
  sortOrder: '',
  user: {
    confirm_password: '',
    email: '',
    enabled: true,
    name: '',
    notify_user: true,
    password: '',
    role: 'admin',
    permissions: [],
  },
})

// getters
const getters = {
  users: (state) => {
    return state.items;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
