import {crud} from '@/utils';
import axios from '../utils/axios';
import store from '../store';

let requestCounter = 0;
const failedRequests = new Map();

const getEmissionsStatus = () => {
  const requestId = `emissionsStatus-${++requestCounter}`;
  const config = {
    method: "GET",
    url: "/v1/broadcasts/status",
    headers: {
      'Request-ID': requestId,
    },
  };

  // TODO : Wait to see the effectiveness of this configuration, and implement this setup in Axios to generalize it to all requests.
  return axios(config).then(res => res.data).catch(error => {
    const failures = failedRequests.get(requestId) || 0;
    failedRequests.set(requestId, failures + 1);
    if (failures + 1 >= 3) {
      store.commit('app/setAlertContent', 'The retrieval of the broadcast status has failed multiple times.');
      store.commit('app/setAlertVariant', 'warning');
      store.commit('app/showAlert'); 
    }
    if (!error.response) {
      store.commit('app/setAlertContent', 'Network or platform issue detected.');
      store.commit('app/setAlertVariant', 'danger');
      store.commit('app/showAlert'); 
    }
    throw error;
  });
};

const Emissions = {
  ...crud('/v1/programs', "Emission"),
  ...crud('/v1/publications', "Publication"),
  getEmissionsStatus,
  
  getBroadcasts: () => {
    return axios({
      method: "GET",
      url: "/v1/broadcasts",
    }).then(res => res.data);
  },

};

export default Emissions;
