import { mapGetters } from 'vuex';

export default {
  name: 'VedemBreadcrumb',
  computed: {
    ...mapGetters('app', [
      'breadcrumb',
      'navTitle',
      'navIcon',
    ])
  }
}
