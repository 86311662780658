import moment from 'moment';

const mutations = {
  addEmission (state, recording) {
    state.items = [...state.items, recording]
  },

  addPublication (state, recording) {
    state.items = [...state.items, recording]
  },

  deleteEmission(state, id) {
    state.items = [...state.items.filter(r => +r.id !== +id)];
  },

  resetEmission(state) {
    state.program = {
      country_start_time: moment().format('HH:mm'),
      country_end_time: moment().add(1, 'hour').format('HH:mm'),
      end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
      frequency: 'daily',
      is_active: false,
      media_source_id: state.program.media_source_id,
      start_date: moment().format('YYYY-MM-DD'),
      title: '',
      url: '',
      week_days: [],
    }
  },

  resetPublication(state) {
    state.publication = {
      media_source_id: '',
      title: '',
      url: '',
    };
  },

  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
  
  setEmissions (state, items) {
    state.items = items
  },

  setEmission(state, emission) {    
    state.program = {
      ...emission,
      week_days: emission.week_days || [],
    };
  },

  setEmissionMedia(state, media_source_id) {
    state.program.media_source_id = media_source_id;
  },

  updateEmissionsStatus(state, updatedStatuses) {
    updatedStatuses.forEach(updated => {
      const emissionIndex = state.items.findIndex(e => e.id === updated.id);
      if (emissionIndex !== -1) {
        state.items[emissionIndex].download_task_status = updated.status;
      }
    });
  },

  setPerPage(state, perPage) {
    state.perPage = perPage;
  },

  setPublication(state, publication) {
    state.publication = publication
  },

  setPublicationMedia(state, media_source_id) {
    state.publication.media_source_id = media_source_id;
  },

  setSortKey (state, sortKey) {
    state.sortKey = sortKey;
  },

  setSortOrder (state, sortOrder) {
    state.sortOrder = sortOrder;
  },

  toggleAllSelect(state) {
    const anyUnchecked = state.items.some(item => !item.selected);

    state.items = state.items.map(item => ({ ...item, selected: anyUnchecked}))
  },

  toggleTableRowSelect(state, id) {
    state.items = state.items.map(item => {
      if(item.id === id) {
        return {...item, selected: !item.selected};
      }

      return item;
    })
  },

  updateEmission(state, recording) {
    state.items = state.items.map(r => +r.id === +recording.id ? recording : r);
  },

  updateProgramKey(state, payload) {
    Object.keys(payload).forEach(key => {
      state.program[key] = payload[key];
    });
  },

  updatePublicationKey(state, payload) {
    Object.keys(payload).forEach(key => {
      state.publication[key] = payload[key];
    });
  }
};

export default mutations;
