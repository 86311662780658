import { mapMutations, mapState } from 'vuex';

export default {
  name: 'VedemAlert',
  computed: {
    ...mapState('app', [
      'alertContent',
      'alertShown',
      'alertVariant',
    ]),
  },
  methods: {
    ...mapMutations('app', [
      'hideAlert',
    ]),
  },
};

