const mutations = {
  createMedia(state, media) {
    state.items = [...state.items, media]
  },

  deleteMedia(state, id) {
    state.items = [...state.items.filter(i => +i.id !== +id)];
  },

  resetMedia(state, permittedTypeOptions) {
    state.media = {
      // eslint-disable-next-line
      country: config?.VUE_APP_COUNTRY || 'Nepal',
      // eslint-disable-next-line
      timezone: config?.VUE_APP_TIMEZONE || 'Asia/Kathmandu',
      title: '',
      url: '',
      type: permittedTypeOptions && permittedTypeOptions[0] ? 
        permittedTypeOptions[0].value : 
        'AudioMediaSource',
      status: false,
    };
  },
 
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },

  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
  
  setMedia (state, items) {
    state.items = items
  },

  setMediaItem(state, media) {
    const { url, ...others } = media;
    state.media = {
      ...others,
      url: url || '',
    };
  },

  setSortKey (state, sortKey) {
    state.sortKey = sortKey;
  },

  setSortOrder (state, sortOrder) {
    state.sortOrder = sortOrder;
  },

  toggleAllSelect(state) {
    const anyUnchecked = state.items.some(item => !item.selected);

    state.items = state.items.map(item => ({ ...item, selected: anyUnchecked}))
  },

  toggleTableRowSelect(state, id) {
    state.items = state.items.map(item => {
      if(item.id === id) {
        return {...item, selected: !item.selected};
      } else {
        return item;
      }
    })
  },

  updateMedia(state, payload) {
    const { id } = payload;
    state.items = state.items.map(i => +i.id === +id ? payload : i) 
  },

  updateMediaKey(state, payload) {
    Object.keys(payload).forEach(key => {
      state.media[key] = payload[key];
    });
  },
}

export default mutations;
