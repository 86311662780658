import {crud} from '@/utils';
import axios from '../utils/axios';

const Recordings = {
  ...crud('/v1/download_tasks', "Recording"),
  
  getSmartMediaSources: (media_source_id) => {
    return axios({
      method: "GET",
      url: `/v1/download_tasks/${media_source_id}/smart_media_sources`,
    }).then(res => res.data);
  },

  postSmartMediaSource: ({ media_source_id, payload }) => {
    return axios({
      method: "POST",
      url: `/v1/download_tasks/${media_source_id}/send_to_smart`,
      data: payload,
    }).then(res => res.data)
  },

  getRecordingStatus: () => {
    return axios({
      method: "GET",
      url: "/v1/transfer/status",
    }).then(res => res.data);
  },
};

export default Recordings
