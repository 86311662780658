// initial state
const state = () => ({
  alertContent: '',
  alertVariant: 'info',
  alertShown: false,
  breadcrumb: ['dashboard'],
  componentTitle: '',
  currentUser: JSON.parse(localStorage.getItem('currentUser')) || {},
  loading: false,
  error: "",
  navItems: [
    {id: 1, icon: 'home', title: 'dashboard', path: '/'},
    {id: 2, icon: 'globe-americas', title: 'media.item', path: '/media'},
    {id: 3, title: 'media.list', path: '/media', parent: 2},
    {id: 4, title: 'media.create', path: '/media/create', parent: 2, restrictedToRoles: ['admin', 'consultant']},
    {id: 5, icon: 'wifi', title: 'programs_and_publications.item', path: '/emissions', render: true},
    {id: 6, title: 'programs.list', path: '/emissions', parent: 5},
    {id: 7, title: 'programs.create', path: '/emissions/create_tvradio', parent: 5, restrictedToRoles: ['admin', 'consultant']},
    {id: 8, title: 'publications.create', path: '/emissions/create_press', parent: 5, restrictedToRoles: ['admin', 'consultant']},
    {id: 9, icon: 'file', title: 'recordings.item', path: '/recordings'},
    {id: 10, title: 'recordings.list', path: '/recordings', parent: 9},
    {id: 11, icon: 'tools', title: 'administration', path: '/users', restrictedToRoles: ['admin']},
    {id: 12, title: 'users.list', path: '/users', parent: 11},
    {id: 13, title: 'users.create', path: '/users/create', parent: 11},
  ],
  selectedNav: 1,
})

// getters
const getters = {
  breadcrumb(state) {
    return state.breadcrumb.join(' / ');
  },

  componentTitle(state) {
    return state.componentTitle;
  },

  navItems: (state) => {
    return state.navItems;
  },

  navIcon(state) {
    const item = state.navItems.find(i => +i.id === state.selectedNav);

    if(item.parent) {
      return state.navItems.find(i => +i.id === +item.parent).icon;
    }

    return item.icon;
  },

  navPath(state) {
    const item = state.navItems.find(i => +i.id === state.selectedNav);
    
    return item.path;
  },

  navTitle(state) {
    const item = state.navItems.find(i => +i.id === state.selectedNav);

    return item.title;
  },
  
  selectedNav: (state) => {
    return state.selectedNav;
  },

}

// mutations
const mutations = {
  hideAlert(state) {
    state.alertShown = false;
  },

  setAlertVariant(state, variant) {
    state.alertVariant = variant
  },

  setAlertContent(state, content) {
    state.alertContent = content
  },

  setBreadcrumb (state, breadcrumb) {
    state.breadcrumb = breadcrumb
  },

  setComponentTitle (state, componentTitle) {
    state.componentTitle = componentTitle
  },

  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  setSelectedNav (state, nav) {
    state.selectedNav = nav
  },

  showAlert(state, duration = 5) {
    state.alertShown = duration;
  },

  setError(state, error) {
    state.error = error;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
