import mediaApi from '@/api/media';
import store from '@/store';

const { 
  createMedia,
  deleteMedia,
  getMediaByID,
  getMedias,
  updateMedia,
} = mediaApi;

const actions = {
  fetchMedia({ commit }) {
    store.commit('app/setLoading', true);

    return getMedias().then(media => {
      store.commit('app/setLoading', false);
      commit('setMedia', media.data);

      return media;
    });
  },

  fetchMediaByID({ commit }, id) {
    store.commit('app/setLoading', true);
    
    return getMediaByID(id).then(media => {
      store.commit('app/setLoading', false);
      commit('setMediaItem', media.data);

      return media;
    });
  },

  addMedia({ commit }, payload) {
    store.commit('app/setLoading', true);

    return createMedia(payload).then(media => {
      store.commit('app/setLoading', false);
     
      commit('createMedia', media.data);

      return media;
    });
  },

  editMedia({ commit }, { id, payload }) {
    store.commit('app/setLoading', true);

    return updateMedia({ id, payload }).then(res => {
      store.commit('app/setLoading', false);
     
      commit('updateMedia', {
        id,
        ...res.data,
      });

      return res;
    });
  },

  removeMedia({ commit }, id) {
    store.commit('app/setLoading', true);

    return deleteMedia(id).then(res => {
      store.commit('app/setLoading', false);
     
      commit('deleteMedia', id);

      return res;
    });
  }
};


export default actions;
