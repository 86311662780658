import userApi from '@/api/users';
import { updateProfile as updateMyProfile } from '@/api/auth';
import store from '@/store';

const { createUser, getUsers, getUserByID, deleteUser, updateUser } = userApi;

const actions = {
  fetchUsers({ commit }) {
    store.commit('app/setLoading', true);

    return getUsers().then(users => {
      store.commit('app/setLoading', false);
      commit('setUsers', users.data);

      return users;
    });
  },

  fetchUserByID({ commit }, id) {
    store.commit('app/setLoading', true);

    return getUserByID(id).then(user => {
      store.commit('app/setLoading', false);
      commit('setUser', user.data)

      return user;
    });
  },

  addUser({ commit }, payload) {
    store.commit('app/setLoading', true);

    return createUser(payload).then(user => {
      store.commit('app/setLoading', false);
      commit('addUser', user.data);

      return user;
    });
  },

  editUser({ commit }, { id, payload }) {
    store.commit('app/setLoading', true);

    return updateUser({ id, payload }).then(res => {
      store.commit('app/setLoading', false);
      commit('updateUser', { ...res.data });

      return res;
    });
  },

  updateProfile({ commit }, {payload}) {
    store.commit('app/setLoading', true);

    return updateMyProfile(payload).then(res => {
      store.commit('app/setLoading', false);
      commit('updateUser', { ...res.data.data });
      
      return res;
    })
  },

  removeUser({ commit }, id) {
    store.commit('app/setLoading', true);
    return deleteUser(id).then(res => {
      store.commit('app/setLoading', false);
      commit('deleteUser', id);

      return res;
    });
  }
}

export default actions;
