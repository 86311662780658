export default {
  name: 'VedemStatus',
  props: [
    'status', 
    'statusType'
  ],
  methods: {
    returnBgByStatusType(type) {
      switch('' || (type && type.toLowerCase())) {
        case "error":
          return 'bg-red';
        case "progress":
          return 'bg-yellow';
        case "success":
          return 'bg-green';
        default:
          return 'bg-gray';
      }
    },
  },
};
