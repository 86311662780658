import { mapState } from 'vuex';

export default {
  name: "VedemHeader",
  props: ['base'],
  computed: {
    ...mapState('app', [
      'currentUser'
    ]),
  },
  methods: {
    handleLogout() {
      window.localStorage.clear();
      this.$router.push('/sign_in');
    },

    handleHomeClick() {
      if (this.$route.name == "Home") { return }
      this.$router.push('/');
    },

    redirect(path) {
      this.$router.push(path)
    },
  },
};
