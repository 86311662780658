export default {
  name: "VedemMultiCheckbox",
  props: {
    'id': {
      type: Number,
    }, 
    'label': {
      type: String,
    }, 
    'labelLeft': {
      type: String,
    },
    'labelRight': {
      type: String,
    }, 
    'name': {
      type: String,
    }, 
    'readonly': {
      type: Boolean,
    },
    'value': {
      type: Array,
    },
  },
  computed: {
    checked() {
      return this.value.includes(this.name);
    },
  },
  methods: {
    handleChange() {
      if(this.value.includes(this.name)) {
        this.$emit('input', this.value.filter(v => v !== this.name));
      } else {
        this.$emit('input',  [ ...this.value, this.name ]);
      }
    }
  }
};
