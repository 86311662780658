import Vue from 'vue';

const mutations = {
  addRecording (state, recording) {
    state.items = [...state.items, recording]
  },

  deleteRecording(state, id) {
    state.items = [...state.items.filter(r => +r.id !== +id)];
  },

  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },

  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
  
  setRecordings (state, items) {
    state.items = items
  },

  setRecording(state, recording) {
    state.recording = recording
  },

  setSortKey (state, sortKey) {
    state.sortKey = sortKey;
  },

  setSortOrder (state, sortOrder) {
    state.sortOrder = sortOrder;
  },

  setSmartMediaSources(state, smartMediaSources) {
    state.smartMediaSources = smartMediaSources;
  },

  setSelectedSmartMediaSource(state, selectedSmartMediaSource) {
    state.selectedSmartMediaSource = selectedSmartMediaSource;
  },

  updateRecordingStatus(state, updatedStatuses) {
    updatedStatuses.forEach(updated => {
      if (updated.download_task_id !== null) {
        const recordingIndex = state.items.findIndex(e => e.id === updated.download_task_id);
        if (recordingIndex !== -1) {
          Vue.set(state.items[recordingIndex], 'statusTransfer', updated.status);
        }
      }
    });
  },

  toggleAllSelect(state) {
    const anyUnchecked = state.items.some(item => !item.selected);

    state.items = state.items.map(item => ({ ...item, selected: anyUnchecked}))
  },

  toggleTableRowSelect(state, id) {
    state.items = state.items.map(item => {
      if(item.id === id) {
        return {...item, selected: !item.selected};
      } else {
        return item;
      }
    })
  },

  updateRecording(state, recording) {
    state.items = state.items.map(r => +r.id === +recording.id ? recording : r);
  },

  updateRecordingKey(state, payload) {
    Object.keys(payload).forEach(key => {
      state.recording[key] = payload[key];
    });
  }
};

export default mutations;
