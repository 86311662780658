import emissionApi from '@/api/emissions'
import store from '@/store';

const { 
  createEmission,
  createPublication,
  deleteEmission,
  deletePublication,
  getBroadcasts,
  getEmissionsStatus,
  getEmissionByID,
  getPublicationByID,
  updateEmission,
} = emissionApi;

const actions = {
  fetchEmissions({ commit }) {
    store.commit('app/setLoading', true);
    return getBroadcasts().then(emissions => {
      store.commit('app/setLoading', false);
      commit('setEmissions', emissions.data);

      return emissions;
    });
  },

  fetchEmissionsStatus({ commit }) {
    return getEmissionsStatus().then(statusUpdates => { 
      commit('updateEmissionsStatus', statusUpdates.data); 
      return statusUpdates;
    }).catch(error => {
      console.error('Error while retrieving broadcast statuses ', error);
      throw error;
    });
  },

  fetchEmissionByID({ commit }, id) {
    store.commit('app/setLoading', true);
    return getEmissionByID(id).then(emission => {
      store.commit('app/setLoading', false);
      commit('setEmission', emission.data);

      return emission;
    });
  },

  fetchPublicationByID({ commit }, id) {
    store.commit('app/setLoading', true);
    
    return getPublicationByID(id).then(publication => {
      store.commit('app/setLoading', false);
      commit('setPublication', publication.data);

      return publication;
    });
  },

  addEmission({ commit }, payload) {
    store.commit('app/setLoading', true);
    
    return createEmission(payload).then(res => {
      store.commit('app/setLoading', false);
      commit('addEmission', res.data);
      return res;
    });
  },

  addPublication({ commit }, payload) {
    store.commit('app/setLoading', true);
    
    return createPublication(payload).then(res => {
      store.commit('app/setLoading', false);
      commit('addEmission', res.data);

      return res;
    })
  },

  editEmission({ commit }, { id, payload }) {
    store.commit('app/setLoading', true);
    
    return updateEmission({ id, payload }).then(res => {
      store.commit('app/setLoading', false);
      commit('updateEmission', res.data);

      return res;
    });
  },

  removeEmission({ commit }, id) {
    store.commit('app/setLoading', true);
    
    return deleteEmission(id).then((res) => {
      store.commit('app/setLoading', false);
      commit('deleteEmission', id);

      return res;
    });
  },

  removePublication({ commit }, id) {
    store.commit('app/setLoading', true);
    
    return deletePublication(id).then(res => {
      store.commit('app/setLoading', false);
      commit('deleteEmission', id);
      
      return res;
    });
  },

  setSortOrder({ commit }, order) {
    commit('setSortOrder', order);
  },

  setSortKey({ commit }, key) {
    commit('setSortKey', key);
  },
}

export default actions;
