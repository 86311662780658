import { resultsPerPageOptions } from '@/utils';
import { mapState } from 'vuex';

export default {
  name: 'VedemTable',
  props: [
    'currentPage', 
    'data', 
    'footer', 
    'headers', 
    'noBorderLeft',
    'paginated', 
    'perPage', 
    'rowMinHeight',
    'setCurrentPage', 
    'selectable', 
    'setPerPage', 
    'setSort', 
    'sortKey', 
    'sortOrder',
    'toggleRowSelect', 
    'toggleAllSelect', 
  ],
  data() {
    return {
      resultsPerPageOptions,
    };
  },
  computed: {
    currentPageModel: {
      get () { return this.currentPage },
      set (value) { this.setCurrentPage(value) },
    },

    maxPage() {
      return Math.ceil(this.rows / this.perPage);
    },

    perPageModel: {
      get () { return this.perPage },
      set (value) { this.setPerPage(value) },
    },

    rows() {
      return this.data.length;
    },

    sortedData() {
      return this.data.sort((a, b) => b.id - a.id);
    },

    ...mapState('app', ['loading']),
  },
};
